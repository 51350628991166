<template>
  <div>
    <FileUploadModel
      :uploadFileListTitle="$t('general.router-terminal-import')"
      :uploadFileListName="$t('terminal.import-list')"
      :uploadURL="'/rest/terminal/import'"
      :extensionName="['xls', 'xlsx']"
      :acceptFileTypes="'.xls,.xlsx'"
      :templateURL="'/tempFiles/TerminalTemplate.xlsx'"
      :templateName="'TerminalTemplate.xlsx'"
      :isShowBack="true"
    />
  </div>
</template>

<script setup></script>
